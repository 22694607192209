export default {
  //super admin
  login: "admin/login",
  getCompanies: "admin/company",
  createCompany: "admin/company",
  updateCompany: "admin/company",
  deleteCompany: "admin/company",
  logout: "admin/logout",
  forgotPassword: "admin/forgot-password",
  resetPassword: "admin/reset-password",
  getAdminLoggedInUser: `admin/get-logged-in-user`,
  //panel
  companyLogin: "company/login",
  getLoggedInUser: `company/get-logged-in-user`,
  companyForgotPassword: "company/forgot-password",
  companyResetPassword: "company/reset-password",
  getPositions: (companyId) => `company/${companyId}/employee-position`,
  managePosition: (companyId) => `company/${companyId}/employee-position`,
  companyForgetPassword: "company/forgot-password",
  companyResetPassword: "company/reset-password",
  manageRoles: (companyId) => `company/${companyId}/role`,
  manageClients: (companyId) => `company/${companyId}/client`,
  manageCourse: (companyId) => `company/${companyId}/course`,
  manageWorkCategories: (companyId) => `company/${companyId}/work-categories`,
  manageWorkSubCategories: (companyId) =>
    `company/${companyId}/work-sub-categories`,
  managePartners: (companyId) => `company/${companyId}/partner`,
  manageWorkCodes: (companyId) => `company/${companyId}/work-codes`,
  manageWorkClassification: (companyId) =>
    `company/${companyId}/work-classification`,
  manageEmployee: "company/employee",
  EmployeeSearch: "company/employee-search",
  manageEmployeeCourses: "company/course-employee",
  manageEmployeeWages: "company/wages",
  manageProjects: (companyId) => `company/${companyId}/project`,
  getAllProjectsWithFilters: (companyId) =>
    `company/${companyId}/project/get-all-with-filter`,
  getCountries: "company/country",
  getSpecificEmployee: "company/get-employee",
  getEmployeeByRole:"company/get-employee-by-role/",
  getAllClients: (companyId) => `company/${companyId}/all/client`,
  manageTeams: (companyId) => `company/${companyId}/team`,
  getTeamByFilters: (companyId) => `company/${companyId}/team/search`,
  getAllPartners: (companyId) => `company/${companyId}/all-partners`,
  getProjectTeam: (companyId) => `company/${companyId}/project/team/`,
  changeTeamStatus: (companyId) => `company/${companyId}/team/suspend`,
  changeProjectStatus: (companyId) => `company/${companyId}/project/suspend`,
  manageTimesheet: (companyId) => `company/${companyId}/project-time-sheet`,
  getTimeSheetData: (companyId) =>
    `company/${companyId}/project-time-sheet/get-all-by-filter`,
  getTimeSheetDataPerDay: (companyId) =>
    `company/${companyId}/project-time-sheet/get-all-by-filter/per-day`,
  getTeamAndProjects: (companyId) =>
    `company/${companyId}/get-teams-and-projects`,
  //DropDown
  getAllCourses: (companyId) => `company/${companyId}/all/course`,
  getEmployeePosition: "company/get-employee",
  getOfficeLocations: "company/office-location",
  manageTeamMembers: (companyId) => `company/${companyId}/team-members`,
  deleteTeamMembers: (companyId) => `company/${companyId}/project/team/remove`,
  getTeamProjects: (companyId) => `company/${companyId}/team/project`,
  getEmployeeTeams: (companyId) => `company/${companyId}/employee-teams`,
  workCodesByWorkcategories: (companyId) =>
    `company/${companyId}/work-codes-by-work-category-id/`,
  getAllCompanies: "get-all-companies",

  //Filters
  courseFilter: "company/course-employee/get-all-with-filter",

  //Profile
  getProfileDetails: (companyId) => `company/${companyId}/user-profile`,
  updatePassword: (companyId) => `company/${companyId}/update-password`,

  //Roles and Permission
  rolesandpermission: "/roles-and-permissoin",

  //thermometer
  performanceReport: "company/project-performance-thermometer-by-block",

  //Reports
  getPeriodReport: (companyId) =>
    `company/${companyId}/reports/period-report/with-date-range/v2`,

  getZoneReport: (companyId) =>
    `company/${companyId}/reports/zone-report/with-date-range`,

  //View Tasks

  viewTasks: `company/1/task-performance-report`,

  //Projects without pagination
  getProjectWithoutPagination: (companyId) =>
    `company/${companyId}/project/get-all-with-filter/with-out-pagination`,

  getEmployeeProjects: (companyId) =>
    `company/${companyId}/employee/get-projects`,
  //timeSheet
  reviewTimesheet: (companyId) =>
    `company/${companyId}/project-time-sheet-review`,
  projectTimeSheetSummary: (companyId) =>
    `company/${companyId}/project-time-sheet-summary`,
  //Permissions
  manageEmployeePermissions: (companyId) => `company/${companyId}/permission`,

  //Project Budget
  getProjectBudget: (companyId) => `company/${companyId}/project-zone-budget`,

  //project progress report
  getlastSevenDaysReport: (companyId) =>
    `company/${companyId}/reports/project/last-seven-days`,
  getThisMonthReport: (companyId) =>
    `company/${companyId}/reports/project/last-month`,
  workCategoryLastSevenDays: (companyId) =>
    `company/${companyId}/reports/project/work-category/last-seven-days`,
  workCodeLastSevenDays: (companyId) =>
    `company/${companyId}/reports/project/work-code/last-seven-days`,
  workCategoryMonthReport: (companyId) =>
    `company/${companyId}/reports/project/work-category/last-month`,
  workCodeMonthReport: (companyId) =>
    `company/${companyId}/reports/project/work-code/last-month`,
  TeamTotalTime: (companyId) =>
    `company/${companyId}/reports/team/work-time/last-seven-days`,
  TeamTotalTimeMonthReport: (companyId) =>
    `company/${companyId}/reports/team/work-time/last-month`,
  projectThermometer: "company/project-thermometer",
  //Get Teams without Pagination
  getTeamsWithoutPagination: (company) =>
    `company/${company}/get-teams-with-out-pagination`,
  filterClients: (companyId) => `company/${companyId}/client/by-team`,
  getHolidays: "company/public-holidays-pagination-with-filters",
  getWorkCodeByWorkSubCategory: (companyId) =>
    `company/${companyId}/work-codes-by-work-sub-category-id/`,
  manageHolidays: "company/public-holidays",
  getEmployeeExcelSheet: "company/employee_export",
  //notifications
  getNotifications: "company/get-notifications",
  markAllNotificationsRead: "company/mark-all-notification-read",
  markSingleNotificationRead: "company/mark-notification-read",
  clearNotifications: "company/clear-all-notifications",
  updateZoneBudget: (companyId) =>
    `company/${companyId}/project-zone-budget-update`,

  getCompanyEmployees: "company/get-company-employee",

  getTeamsByCompany: (companyId) =>
    `company/${companyId}/employee-teams-by-company`,

  getMonthtoDateReport: (companyId) =>
    `company/${companyId}/reports/month-to-date/with-filters`,

  getProjectManagers: "company/get-project-managers",

  getProjectReport: (companyId) =>
    `company/${companyId}/reports/project-report`,

  exportProjectReport: (companyId) =>
    `company/${companyId}/export/project-report`,

  exportZoneReport: (companyId) =>
    `company/${companyId}/export/reports/project-zone-report`,

  periodReportExcelExport: (companyId) =>
    `company/${companyId}/export-period-report/with-date-range/v2`,

  getZoneReportHtml: "projectZoneReportDownload",

  addEmployeePermissions: (employeeId) =>
    `company/add-user-permission/${employeeId}`,
  getEmployeePermissions: (employeeId) =>
    `company/get-user-permission/${employeeId}`,
  getProjectCategories: (company) =>
    `company/${company}/work-categories-for-budget`,
  exportQAReport: (company) =>
    `company/${company}/export-quality-assurance-reports`,
  getQaReport: (company) => `company/${company}/quality-assurance-reports`,

  updateEmployeeProfile: (employee_id) =>
    `company/update-employee-with-out-validation/${employee_id}`,

  changeProjectStatus: (companyId) =>
    `company/${companyId}/change/project-status`,

  exportMonthToDateReport: (companyId) =>
    `company/${companyId}/export/month-to-date/with-filters`,

  colorCode: "company/color-code",

  billingReport: "company/billing-report",
  exportBillingReport: "company/export-billing-report",

  // subtask and task

  createTask: (companyId) => `company/${companyId}/task`,
  getTasksnSubtask: (companyId) =>
    `company/${companyId}/tasks/get-all-with-filter`,
  deleteTask: (companyId, taskId) => `company/${companyId}/task/${taskId}`,
  updateTask: (companyId, taskId) =>
    `company/${companyId}/tasks/update-status/${taskId}`,
  updateAllTask: (companyId, taskId) =>
      `company/${companyId}/task/${taskId}`,
};
